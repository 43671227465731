import React, { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route, Link, useLocation } from 'react-router-dom';
import PageTracker from './components/PageTracker'; 
import Navbar from "./components/Navbar/Navbar";
import Footer from "./components/Footer/Footer";
import Home from "./components/Pages/Home";
import Story from "./components/Pages/Story";
import Product from "./components/Pages/Product";
import Contact from "./components/Pages/Contact";
import ImageUpload from "./components/ImageUpload";
import Models from "./components/Pages/Models";
import ModelView from "./components/Pages/ModelView";
import FAQ from "./components/Pages/Faq";
import ProcessPhases from "./components/Pages/How";
import Form from "./components/ContactForm";
import LoadingScreen from './components/LoadingScreen/LoadingScreen';
import { ContentProvider } from "./components/contentful-context";
import { LoadingProvider } from './context/LoadingContext';
import PrivacyPolicy from "./components/PrivacyPolicy";
import TermsOfService from "./components/TermsOfService";
import InterestProfiles from "./components/InterestProfiles";
import ContactForm from "./components/ContactForm";
import InvestInUsForm from "./components/InvestInUsForm";

const NotFound = () => (
  <div className="flex flex-col text-center py-10 h-screen justify-center">
    <h1 className="text-6xl">404</h1>
    <div className="text-xl">Sorry, this page does not exist</div>
    <div className="text-xl">
      Go back to the
      <Link to="/" className="underline">
        homepage
      </Link>
    </div>
  </div>
);

const Upload = () => (
  <div className="h-[800px]">
    <ImageUpload />
  </div>
);

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

function App() {
  return (
    <div className="min-h-screen flex flex-col">
      <Router>
        <PageTracker />
        <ContentProvider>
          <Navbar />
          <LoadingProvider>
          <LoadingScreen />
          <main className="flex-grow">
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/story" element={<Story />} />
              <Route path="/product" element={<Product />} />
              {/* <Route path="/contact" element={<Contact />} /> */}
              <Route path="/models" element={<Models />} />
              <Route path="/models/:name" element={<ModelView />} />
              <Route path="/faqs" element={<FAQ />} />
              {/* <Route path="/how" element={<ProcessPhases />} /> */}
              <Route path="/contact/build-with-us/:formId" element={<ContactForm />} />
              <Route path="/contact/invest-in-us/:formId" element={<InvestInUsForm />} />
              <Route path="/interest" element={<InterestProfiles />} />
              <Route path="/privacy" element={<PrivacyPolicy />} />
              <Route path="/terms" element={<TermsOfService />} />
              <Route path="*" element={<NotFound />} />
            </Routes>
          </main>
          </LoadingProvider>
          <Footer />
        </ContentProvider>
      </Router>
    </div>
  );
}

export default App;
